import {
  Environment,
  aipriseProductionFetch,
  aipriseSandboxFetch,
} from "../utils/aiprise-fetch.js";

export type SessionTypePayload = {
  mode?: Environment;
  verification_session_id: string;
};

export type SessionTypeResponse = {
  verification_session_type: "USER_ENTITY_TYPE" | "BUSINESS_ENTITY_TYPE";
};

export const getVerificationSessionType = async ({
  mode,
  verification_session_id,
}: SessionTypePayload) => {
  const aipriseFetch = mode === "PRODUCTION" ? aipriseProductionFetch : aipriseSandboxFetch;
  const response = await aipriseFetch<SessionTypeResponse>(
    "/api/v1/verify/get_verification_session_type",
    {
      method: "POST",
      body: JSON.stringify({
        verification_session_id,
      }),
    }
  );
  return response;
};
