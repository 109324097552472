import { LitElement } from "lit";
import { property } from "lit/decorators.js";

import { Environment } from "./aiprise-fetch.js";
import { AiPriseErrorEvent, AiPriseSessionEvent } from "../types/aiprise-session-event.js";

export class AiPriseLitElement extends LitElement {
  // 1 - FIX RENDER ROOT
  protected createRenderRoot(): Element | ShadowRoot {
    // (i) Disables shadow DOM and enables light DOM.
    // (ii) We lose "scoped" CSS because of this. Hence class names should follow BEM conventions to avoid conflicts with host application.
    // (iii) This also allows the users of our library to override our button's CSS if required (which is the desired behaviour)
    return this;
  }

  // 2 - COMMON PROPS

  // API Properties
  @property({ type: String }) mode: Environment | undefined;
  @property({ type: String, attribute: "template-id" }) templateId = "";
  @property({ attribute: "session-id" }) sessionIdFromProps: string | undefined; // Adding `fromProps` to avoid confusion with verificationSessionId state used by aiprise-frame
  @property({ attribute: "callback-url" }) callbackUrl: string | undefined;
  @property({ attribute: "client-reference-id" }) clientReferenceId: string | undefined;
  @property({ attribute: "client-reference-data" }) clientReferenceData: string | undefined; // JSON string
  @property({ attribute: "user-data" }) userData: string | undefined; // JSON string
  @property({ attribute: "business-data" }) businessData: string | undefined; // JSON string
  @property({ attribute: "additional-info" }) additionalInfo: string | undefined; // JSON string

  // Style Properties
  @property({ type: String }) icon: string | undefined; // Default AiPrise logo will be used
  @property({ type: String }) color: string | undefined; // Default is AiPrise theme color
  @property({ type: String }) theme: string | undefined; // JSON (theme_options)

  // 3 - EVENT DISPATCHERS / FORWARDERS

  dispatchStarted = (x: string | AiPriseSessionEvent) => {
    const verification_session_id = typeof x === "string" ? x : x.detail.verification_session_id;
    this.dispatchEvent(
      new CustomEvent("aiprise:started", {
        detail: { verification_session_id },
      })
    );
  };

  dispatchResumed = (x: string | AiPriseSessionEvent) => {
    const verification_session_id = typeof x === "string" ? x : x.detail.verification_session_id;
    this.dispatchEvent(
      new CustomEvent("aiprise:resumed", {
        detail: { verification_session_id },
      })
    );
  };

  dispatchAbandoned = (x: string | AiPriseSessionEvent) => {
    const verification_session_id = typeof x === "string" ? x : x.detail.verification_session_id;
    this.dispatchEvent(
      new CustomEvent("aiprise:abandoned", {
        detail: { verification_session_id },
      })
    );
  };

  // Verification form completed
  dispatchSuccessful = (x: string | AiPriseSessionEvent) => {
    const verification_session_id = typeof x === "string" ? x : x.detail.verification_session_id;
    this.dispatchEvent(
      new CustomEvent("aiprise:successful", {
        detail: { verification_session_id },
      })
    );
  };

  // Verification form completed AND user clicked "continue" or "x"
  dispatchContinue = (x: string | AiPriseSessionEvent) => {
    const verification_session_id = typeof x === "string" ? x : x.detail.verification_session_id;
    this.dispatchEvent(
      new CustomEvent("aiprise:continue", {
        detail: { verification_session_id },
      })
    );
  };

  // DEPRECATED: Keeping for backward compatibility
  dispatchCompleted = (x: string | AiPriseSessionEvent) => {
    const verification_session_id = typeof x === "string" ? x : x.detail.verification_session_id;
    this.dispatchEvent(
      new CustomEvent("aiprise:completed", {
        detail: { verification_session_id },
      })
    );
  };

  dispatchError = (x: string | AiPriseErrorEvent) => {
    const error_code = typeof x === "string" ? x : x.detail.error_code;
    this.dispatchEvent(
      new CustomEvent("aiprise:error", {
        detail: { error_code },
      })
    );
  };
}
