// Lit
import { html, nothing } from "lit";
import { ifDefined } from "lit/directives/if-defined.js";
import { customElement, property, state } from "lit/decorators.js";

// Utils / Services
import { AiPriseLitElement } from "../utils/aiprise-element.js";

// Typescript
import { DetailedHTMLProps, HTMLAttributes } from "react";
import { AiPriseErrorEvent, AiPriseSessionEvent } from "../types/aiprise-session-event.js";
import { AiPriseElementProps } from "../types/aiprise-element-props.js";

// Colors, Icons & Style
import assets from "../assets.js";
import { buttonResetStyle } from "../styles/button-reset.js";

// Components
import "lit-portal";

@customElement("aiprise-button")
export class AiPriseButton extends AiPriseLitElement {
  // 1 - PROPS

  @property({ type: String }) title = "Verify with AiPrise";
  @property({ type: String, attribute: "text-color" }) textColor: string | undefined;

  // 2 - STATE

  @state() iframeHasError = false;
  @state() iframeIsSuccessful = false; // When user fully completes the form
  @state() iframeVisible = false;
  @state() iframeVerificationSessionId = "";

  // 3 - FUNCTIONS

  handleButtonClick = () => {
    this.iframeVisible = true;
  };

  handleCloseClick = () => {
    // If iframe has error OR when form is fully filled, close without confirmation
    if (this.iframeHasError) {
      this.iframeVisible = false;
      return;
    }
    if (this.iframeIsSuccessful) {
      this.dispatchContinueAndResetState(this.iframeVerificationSessionId);
      return;
    }
    // If iframe doesn't have error or isn't completed, ask for confirmation first
    const shouldClose = confirm("Are you sure you want to cancel your verification?");
    if (shouldClose) {
      this.iframeVisible = false;
      this.dispatchAbandoned(this.iframeVerificationSessionId);
    }
  };

  setIframeSessionIdAndDispatchStarted = (e: AiPriseSessionEvent) => {
    this.iframeVerificationSessionId = e.detail.verification_session_id;
    this.dispatchStarted(e);
  };

  setIframeSuccessfulAndDispatchSuccessful = (e: AiPriseSessionEvent) => {
    this.iframeIsSuccessful = true;
    this.dispatchSuccessful(e);
  };

  dispatchContinueAndResetState = (e: AiPriseSessionEvent | string) => {
    this.dispatchContinue(e);
    this.iframeHasError = false;
    this.iframeIsSuccessful = false;
    this.iframeVisible = false;
    this.iframeVerificationSessionId = "";
  };

  setIframeErrorAndDispatchError = (e: AiPriseErrorEvent | string) => {
    this.iframeHasError = true;
    this.dispatchError(e);
  };

  // 4 - CSS & HTML

  render() {
    const buttonColor = this.color ?? assets.colors.aiprise;
    const textColor = this.textColor ?? "#ffffff";

    return html`
      <!-- CSS -->
      ${buttonResetStyle}
      <style>
        /* 1 - BUTTON */

        .aiprise-button {
          display: flex;
          align-items: center;
          border: 1px solid ${buttonColor};
          border-radius: 2px;
          font-family: Arial, sans-serif;
          cursor: pointer;
          box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
          /* Animation */
          transition-property: all;
          transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
          transition-duration: 300ms;
        }
        .aiprise-button:hover {
          box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
        }

        /* Button Icon */
        .aiprise-button__icon {
          display: flex;
          align-items: center;
          justify-content: center;
          padding-left: 8px;
          padding-right: 8px;
        }
        .aiprise-button__icon img {
          width: 20px;
          height: 20px;
          object-fit: contain;
        }

        /* Button Text */
        .aiprise-button__title {
          padding: 8px 12px;
          font-size: 15px;
          background: ${buttonColor};
          color: ${textColor};
        }

        /* 2 - MODAL */

        .aiprise-modal {
          display: flex;
          align-items: center;
          justify-content: center;
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: rgba(0, 0, 0, 0.5);
          z-index: 2147483647;
        }

        /* Modal Card */
        .aiprise-modal__main {
          position: relative;
          background: white;
          width: 400px;
          height: 750px;
          max-width: 100vw;
          max-height: 100vh;
          border-radius: 4px;
          box-shadow: 0 25px 50px -12px rgb(0 0 0 / 0.25);
        }

        /* Modal Close Button */
        .aiprise-modal__close {
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          top: -13px;
          right: -13px;
          width: 26px;
          height: 26px;
          border: none;
          border-radius: 50%;
          background-color: #b9b9b9;
          cursor: pointer;
          box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
        }
        .aiprise-modal__close img {
          width: 12px;
          height: 12px;
        }
      </style>

      <!-- Button -->
      <button
        class="aiprise-button aiprise-button--reset"
        type="button"
        @click="${this.handleButtonClick}"
      >
        <div class="aiprise-button__icon">
          <img src="${this.icon ?? assets.icons.aiprise}" alt="Logo" />
        </div>
        <div class="aiprise-button__title">${this.title}</div>
      </button>

      <!-- Modal -->
      ${this.iframeVisible
        ? html`
            <lit-portal
              to="aiprise-modal-root"
              .body=${html`
                <div class="aiprise-modal">
                  <div class="aiprise-modal__main">
                    <!-- Iframe -->
                    <aiprise-frame
                      mode="${ifDefined(this.mode)}"
                      template-id="${this.templateId}"
                      session-id="${ifDefined(this.sessionIdFromProps)}"
                      callback-url="${ifDefined(this.callbackUrl)}"
                      client-reference-id="${ifDefined(this.clientReferenceId)}"
                      client-reference-data="${ifDefined(this.clientReferenceData)}"
                      user-data="${ifDefined(this.userData)}"
                      business-data="${ifDefined(this.businessData)}"
                      additional-info="${ifDefined(this.additionalInfo)}"
                      icon="${ifDefined(this.icon)}"
                      color="${ifDefined(this.color)}"
                      theme="${ifDefined(this.theme)}"
                      @aiprise:started="${this.setIframeSessionIdAndDispatchStarted}"
                      @aiprise:successful="${this.setIframeSuccessfulAndDispatchSuccessful}"
                      @aiprise:continue="${this.dispatchContinueAndResetState}"
                      @aiprise:completed="${this.dispatchCompleted}"
                      @aiprise:error="${this.setIframeErrorAndDispatchError}"
                    ></aiprise-frame>

                    <!-- Close Button -->
                    <button
                      class="aiprise-modal__close aiprise-button--reset"
                      @click="${this.handleCloseClick}"
                    >
                      <img src="${assets.icons.cross}" />
                    </button>
                  </div>
                </div>
              `}
            ></lit-portal>
          `
        : nothing}
    `;
  }
}

// Typescript

declare global {
  interface HTMLElementTagNameMap {
    "aiprise-button": AiPriseButton;
  }

  interface HTMLElementEventMap {
    "aiprise:started": AiPriseSessionEvent;
    "aiprise:resumed": AiPriseSessionEvent;
    "aiprise:successful": AiPriseSessionEvent;
    "aiprise:continue": AiPriseSessionEvent;
    "aiprise:completed": AiPriseSessionEvent; // DEPRECATED: Keeping for backward compatibility
    "aiprise:abandoned": AiPriseSessionEvent;
  }

  // For React
  namespace JSX {
    interface IntrinsicElements {
      "aiprise-button": DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement> &
        AiPriseElementProps & {
          title?: string;
          color?: string;
          "text-color"?: string;
        };
    }
  }
}
