import {
  Environment,
  aipriseProductionFetch,
  aipriseSandboxFetch,
} from "../utils/aiprise-fetch.js";

export type BaseSessionApiPayload = {
  mode?: Environment;
  template_id: string;
  callback_url?: string;
  client_reference_id?: string;
  client_reference_data?: unknown;
  theme_options?: Record<string, string | undefined>;
  additional_info?: Array<{
    additional_info_type?: string;
    additional_info_data?: any;
  }>;
};

export type UserSessionApiPayload = BaseSessionApiPayload & {
  user_data?: {
    first_name?: string;
    middle_name?: string;
    last_name?: string;
    date_of_birth?: string; // YYYY-MM-DD
    phone_number?: string; // E.164 format
    email_address?: string;
    ip_address?: string;
    address?: Address;
  };
};

export type BusinessSessionApiPayload = BaseSessionApiPayload & {
  business_data?: {
    name?: string;
    business_entity_id?: string;
    tax_identification_number?: string;
    state_code?: string;
    country_code?: string;
    website?: string;
    phone_numbers?: string[];
    addresses?: Address[];
  };
};

export type Address = {
  address_street_1?: string;
  address_street_2?: string;
  address_city?: string;
  address_state?: string;
  address_zip_code?: string;
  address_country?: string;
};

export type SessionApiPayload = UserSessionApiPayload | BusinessSessionApiPayload;

export type SessionApiResponse = {
  verification_url: string;
};

// This object represents what the below function returns (not what the API returns)
export type SessionResponse = SessionApiResponse & {
  verification_session_id: string;
};

export const createVerificationSession = async ({
  mode,
  ...payload
}: SessionApiPayload): Promise<SessionResponse> => {
  const aipriseFetch = mode === "PRODUCTION" ? aipriseProductionFetch : aipriseSandboxFetch;
  const response = await aipriseFetch<SessionApiResponse>(
    "/api/v1/verify/get_verification_session_url_for_sdk",
    {
      method: "POST",
      body: JSON.stringify({
        redirect_uri: "iframe",
        ...payload,
      }),
    }
  );
  // Extract session ID from the URL
  const url = new URL(response.verification_url);
  const verification_session_id = url.searchParams.get("verification_session_id");
  const business_onboarding_session_id = url.searchParams.get("business_onboarding_session_id");
  const session_id = verification_session_id || business_onboarding_session_id || "";
  // Resolve the request
  return {
    verification_url: response.verification_url,
    verification_session_id: session_id,
  };
};
