import { html } from "lit";

export const buttonResetStyle = html`
  <style>
    /* Make the button look same on all browsers */
    .aiprise-button--reset {
      outline: none;
      margin: 0;
      padding: 0;
      background: transparent;
    }
  </style>
`;
