import { html } from "lit";
import { customElement } from "lit/decorators.js";
import { AiPriseLitElement } from "../utils/aiprise-element.js";

@customElement("aiprise-spinner")
export class AiPriseSpinner extends AiPriseLitElement {
  render() {
    return html`
      <!-- CSS -->
      <style>
        .aiprise-spinner {
          display: inline-block;
          position: relative;
          width: 50px;
          height: 50px;
        }

        .aiprise-spinner div {
          box-sizing: border-box;
          display: block;
          position: absolute;
          width: 40px;
          height: 40px;
          margin: 5px;
          border: 5px solid #191919;
          border-color: #191919 transparent transparent transparent;
          border-radius: 50%;
          animation: aiprise-spinner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        }

        .aiprise-spinner div:nth-child(1) {
          animation-delay: -0.45s;
        }

        .aiprise-spinner div:nth-child(2) {
          animation-delay: -0.3s;
        }

        .aiprise-spinner div:nth-child(3) {
          animation-delay: -0.15s;
        }

        @keyframes aiprise-spinner {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }
      </style>

      <!-- HTML -->
      <div class="aiprise-spinner">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    `;
  }
}
