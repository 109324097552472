export type Environment = "SANDBOX" | "PRODUCTION";

export const aipriseSandboxFetch = <T>(url: string, init?: RequestInit) => {
  return fetchV2<T>("https://api-sandbox.aiprise.com" + url, init);
};

export const aipriseProductionFetch = <T>(url: string, init?: RequestInit) => {
  return fetchV2<T>("https://api.aiprise.com" + url, init);
};

// This just makes fetch response "typed" and handles logging of errors
const fetchV2 = async <T>(input: RequestInfo | URL, init?: RequestInit) => {
  const response = await fetch(input, init);
  if (!response.ok) {
    const err = await response.json();
    console.log("Response Error!", err);
    throw new Error("HTTP status code: " + response.status);
  }
  return response.json() as T;
};
